<template>
  <div class="homepage">
    <div class="rf-sign-up">
      <b-container>
        <b-row class="mt-5">
          <b-col class="position-relative mt-5 " cols="12" md="6">
            <h1 class="accomodation-title">{{ $t('Login with your email') }}</h1>
            <b-row class="p-2">
              <div class="login-modal" @keyup.enter.prevent="login">
                <RFForm :fields="fields" :loading="loading" class="mb-3" @submit="login"/>
                <router-link class="forgot-password" to="/auth/forgot-password/send-email">{{ $t('Forgot password?') }}
                </router-link>
                <div class="accomodation-text">
                  <p class="text-line">{{ $t('Or you can use') }}</p>
                </div>
                <!-- <b-row class="p-2">
                  <RFButton
                    transparent
                    border-color="transparent"
                    :icon="['fa', 'envelope']"
                    :iconSize="'lg'"
                    color="#fff"
                    label="Signup with falso Google"
                    @on-click="fakeGoogle()"
                  />
                </b-row> -->

                <b-row class="py-2 ">
                  <button class="facebook-login-btn" @click="$refs.facebookButton.login()">
                    <img class="facebook-login-img" src="@/assets/images/facebook_letter.png"/>
                    Log In To Facebook
                  </button>
                  <facebook-login v-if="fbAppId" ref="facebookButton" :appId="fbAppId" class="facebook-login"
                                  @login="onLogin"
                                  @sdk-loaded="sdkLoaded">
                  </facebook-login>
                </b-row>
                <b-row class="py-2">
                  <div v-if="clientId" id="google-login-btn" v-google-identity-login-btn="{ clientId, locale:'es' }"
                       class="google-login-btn">
                    Google
                  </div>
                </b-row>
                <!--b-row class="p-2">
                  <RFButton
                    transparent
                    border-color="transparent"
                    :icon="['fab', 'facebook-f']"
                    :iconSize="'lg'"
                    color="#fff"
                    :label="$t('Login with ') + 'Facebook'"
                    @on-click="loginWithFacebook()"/>
                </!--b-row-->
              </div>
            </b-row>
            <div class="accommodation-texture first d-none d-md-block"/>
            <div class="accommodation-texture d-none d-md-block"/>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-type">
      <b-container fluid>
        <b-row>
          <b-col class="rf-sign-up apartments mb-5 mb-md-0 px-0" cols="12" md="6">
            <div class="rf-apartments">
              <h1 class="title">{{ $t('Apartments') }}</h1>
              <router-link :to="{ name: 'apartments-page' }">
                <RFButton :label="$t('Discover')" background="#67AEA6" border-color="transparent" class="px-5"
                          color="#fff"/>
              </router-link>
            </div>
          </b-col>
          <b-col class="rf-sign-up buildings px-0" cols="12" md="6">
            <div class="rf-buildings">
              <h1 class="title">{{ $t('RooF Buildings') }}</h1>
              <router-link to="/buildings">
                <RFButton :label="$t('Discover')" background="#E26F5A" border-color="transparent" class="px-5"
                          color="#fff"/>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-icons mt-5">
      <img class="w-100" src="@/assets/images/home_rf_icons.jpg">
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFValidation from '@/components/forms/RFValidation'
import RFLoader from '@/components/forms/RFLoader'
import RFForm from '@/components/forms/RFForm'
import loginFields from '@/json/forms/login.json'
import {extractValueByField, replaceFieldValue} from '@/helpers'
import {isAdmin} from '@/helpers/functions'
import {authService} from '@/services'
import i18n from "@/plugins/i18n";
import facebookLogin from 'facebook-login-vuejs';

import GoogleSignInButton from 'vue-google-identity-login-btn'

export default {
  directives: {
    GoogleSignInButton
  },
  components: {
    RFInput,
    RFButton,
    RFValidation,
    RFForm,
    RFLoader,
    facebookLogin
  },
  data() {
    return {
      clientId: null,
      fbAppId: null,
      useEmail: false,
      user: {
        firstname: '',
        lastname: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: ''
      },
      loading: false,
      fields: loginFields,
      form: {
        email: null,
        password: null,
        authType: 'MAIL'
      },
    }
  },
  destroyed() {
    replaceFieldValue(this.fields, 'email', null)
    replaceFieldValue(this.fields, 'password', null)
  },
  computed: {},
  beforeMount() {
    this.clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
    this.fbAppId = process.env.VUE_APP_FB_APP_ID
  },
  methods: {
    // async fakeGoogle () {
    //   this.loading = true
    //   this.form.email = 's111@fulanosky.com'
    //   this.form.password = '3211534545454661'
    //   this.form.authType = 'GOOGLE'
    //   this.login()
    // },
    facebookClick() {
      console.log(this.$refs.facebookButton)
    },
    async onGoogleAuthSuccess(jwtCredentials) {
      const profileData = JSON.parse(atob(jwtCredentials.split('.')[1]));
      this.form.email = profileData.email
      this.form.password = profileData.sub
      this.form.authType = 'GOOGLE'
      await this.login()
    },
    async getUserData() {
      // this.loading = true
      this.$store.state.userStore.Facebook.api('/me', 'GET', {fields: 'id,name,email'},
        userInformation => {
          this.form.email = userInformation.email
          this.form.password = userInformation.id
          this.form.authType = 'FACEBOOK'
          this.login()
        }
      )
      this.loading = false
    },
    sdkLoaded(payload) {
      this.$store.commit('userStore/setFacebook', payload)
      if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.isConnected = true
      this.getUserData()
    },
    async login() {
      this.loading = true

      if (this.form.authType === 'MAIL') {
        this.form.email = i18n.t(extractValueByField(this.fields, 'email'))
        this.form.password = i18n.t(extractValueByField(this.fields, 'password'))
      }

      const loggedUser = await authService.login(this.form)

      this.loading = false

      if (this.$store.state.userStore.loggedIn) {
        replaceFieldValue(this.fields, 'email', null)
        replaceFieldValue(this.fields, 'password', null)

        if (isAdmin(loggedUser.roles)) {
          this.$router.push({name: 'admin'})
        } else {
          this.$router.push({name: 'profile'})
        }
      }
    },
  },
}
</script>

<style scoped>
.facebook-login {
  display: none;
}

.facebook-login-btn {
  color: #FFF;
  font-size: 16px;
  min-width: 300px;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  border: none;
  border-radius: 3px;
  line-height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-login-img {
  height: 30px;
  width: 30px;
  margin-right: 15px;
}

.google-login-btn {
  margin-left: 10px;
}

.btn-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
}

.btn-cont > div {
  width: 300px;
  text-align: center;
}
</style>

<style lang="scss" scoped>
.login-modal {
  background-color: $white;
  padding: 10px 20px 0px 20px;
  width: 300px;

  .forgot-password {
    @include font-style($montserrat, 'semibold', $font-14);
    color: $dark-blue;
  }
}

.homepage {
  .rf-sign-up {
    margin-top: 10vh;
    position: relative;

    .accomodation-title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }

    .accomodation-text {
      margin-top: 40px;

      .text-line {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        margin: 0;
      }
    }

    .accommodation-texture {
      width: 100vw;
      background-image: url('/images/gray_texture.png');
      position: absolute;
      height: 200px;
      z-index: 5;

      &.first {
        left: -100vw;
      }
    }

    .booking-form-container {
      background-color: $dark-blue;
      padding: 15px;
      position: relative;
      z-index: 5;

      &:after,
      &:before {
        content: '';
        width: 100vw;
        height: 100%;
        background-color: $dark-blue;
        position: absolute;
        top: 0;
        z-index: -1;
      }

      &:before {
        right: 50%;
      }

      &:after {
        left: 50%;
      }

      .booking-label {
        @include font-style($montserrat-bold, 'bold', $font-14);
        color: $white;
        margin-top: 20px;
        white-space: nowrap;
      }
    }
  }

  .rf-explore {
    background: $gray;
    padding: 30px 0;

    .explore-text {
      @include font-style($montserrat-bold, 'bold', $font-20);
      text-align: center;
      margin: 0;
      color: $dark-blue;
    }
  }

  .rf-type {
    position: relative;

    .rf-sign-up {
      height: 50vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.apartments {
        background-image: url('/images/A-image.jpg');
        border-bottom: 10px solid $green;

        .rf-apartments {
          text-align: center;
        }
      }

      &.buildings {
        background-image: url('/images/R-image.jpg');
        border-bottom: 10px solid $orange;

        .rf-buildings {
          text-align: center;
        }
      }
    }

    .title {
      @include font-style($montserrat-bold, 'bold', $font-24);
      color: $white;
    }
  }

  .rf-services {
    padding: 100px 0;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
      margin-bottom: 50px;
    }

    .text {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
    }

    .service-image {
      width: 60%;
    }
  }

  .rf-roof-world {
    background-image: url('/images/home_roof_world.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    align-items: center;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }

    .text {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
      margin-top: 100px;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .homepage {
    .rf-sign-up {
      .accomodation-text {
        width: 80%;
      }

      .booking-form-container {
        padding: 50px 0 50px 50px;
        position: static;
        z-index: 0;

        &:before {
          display: none;
        }

        &:after {
          display: block;
        }

        .booking-form {
          position: relative;
          z-index: 10;
        }
      }
    }

    .rf-type {
      .rf-sign-up {
        height: 60vh;

        &.buildings,
        &.apartments {
          // align-items: flex-end;

          .rf-buildings,
          .rf-apartments {
            padding: 0px;
            text-align: center;
          }
        }
      }
    }

    .rf-services {
      .service-image {
        width: 40%;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .homepage {
    .rf-services {
      .service-image {
        width: 80px;
      }
    }
  }
}
</style>
